<template>
  <header
    class="relative z-[20] mx-auto py-4 md:py-9 2xl:mx-[120px] 2xl:max-w-[1536px] 2mxl:mx-auto"
    :class="{
      'border-b border-solid border-white/10': bordered,
    }"
  >
    <nav class="navbar w-full bg-transparent">
      <div class="navbar-start">
        <img src="../assets/svg/logo-white.svg" alt="Logo" loading="eager" />
      </div>

      <div class="navbar-center hidden lg:flex">
        <ul class="menu menu-horizontal px-1">
          <li v-for="nav in mainNav" :key="nav.name">
            <nuxt-link
              :to="nav.uri"
              class="px-4 py-1.5 text-center hover:bg-white/10"
              :class="{
                'bg-white/10': nav.active,
              }"
              >{{ nav.name }}</nuxt-link
            >
          </li>
        </ul>
      </div>
      <div class="navbar-end">
        <div v-show="isAuthenticated" :class="{ 'hidden items-center gap-4 lg:flex': isAuthenticated }">
          <nuxt-link :to="profileUrl" class="rounded-2xl border border-solid border-white/20 bg-white/10 px-6 py-2 text-sm hover:bg-white/20"
            >Profile</nuxt-link
          >
          <nuxt-link :to="CLIENT_URLS.Account" class="rounded-2xl border border-solid border-white/20 bg-white/10 px-6 py-2 text-sm hover:bg-white/20"
            >My Account</nuxt-link
          >
        </div>

        <div v-show="!isAuthenticated && allowGuestAuth" :class="{ 'hidden items-center gap-4 lg:flex': !isAuthenticated && allowGuestAuth }">
          <nuxt-link :to="CLIENT_URLS.SignIn" class="rounded-2xl border border-solid border-white/20 bg-white/10 px-6 py-2 text-sm hover:bg-white/20"
            >Sign In</nuxt-link
          >
          <nuxt-link :to="CLIENT_URLS.SignUp" class="rounded-2xl border border-solid border-white/20 bg-white/10 px-6 py-2 text-sm hover:bg-white/20"
            >Sign Up</nuxt-link
          >
        </div>

        <div v-show="allowGuestAuth || isAuthenticated" class="dropdown dropdown-end">
          <div tabindex="0" role="button" class="btn btn-ghost lg:hidden">
            <nuxt-icon name="nav/menu" class="cursor-pointer text-2xl descendant:text-white" />
          </div>

          <ul tabindex="0" class="menu dropdown-content menu-sm z-[20] w-[16rem] rounded-box bg-main-theme-dark p-2 shadow">
            <li v-for="nav in mainNav" :key="nav.name" class="mb-2 flex w-full">
              <nuxt-link :to="nav.uri" class="flex w-full items-center justify-center px-4 py-1 hover:bg-white/10">{{ nav.name }}</nuxt-link>
            </li>

            <li v-show="isAuthenticated" :class="{ 'flex flex-row justify-center gap-2 pt-4': isAuthenticated }">
              <nuxt-link :to="profileUrl" class="rounded-2xl border border-solid border-white/20 bg-white/10 px-6 py-2 text-sm hover:bg-white/20"
                >Profile</nuxt-link
              >
              <nuxt-link
                :to="CLIENT_URLS.Account"
                class="rounded-2xl border border-solid border-white/20 bg-white/10 px-6 py-2 text-sm hover:bg-white/20"
                >My Account</nuxt-link
              >
            </li>
            <li v-show="!isAuthenticated" :class="{ 'flex flex-row justify-center gap-2 pt-4': !isAuthenticated }">
              <nuxt-link
                :to="CLIENT_URLS.SignIn"
                class="rounded-2xl border border-solid border-white/20 bg-white/10 px-6 py-2 text-sm hover:bg-white/20"
                >Sign In</nuxt-link
              >
              <nuxt-link
                :to="CLIENT_URLS.SignUp"
                class="rounded-2xl border border-solid border-white/20 bg-white/10 px-6 py-2 text-sm hover:bg-white/20"
                >Sign Up</nuxt-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { CLIENT_URLS } from "~/constants";
import { useAuthStore } from "~/store/authStore";

defineProps<{
  bordered?: boolean;
}>();

const authStore = useAuthStore();

const { isAuthenticated, authUser } = storeToRefs(authStore);

const route = useRoute();

const allowGuestAuth = useRuntimeConfig().public.siteMeta.allowGuestAuth;

const profileUrl = computed(() => {
  if (!authUser.value) {
    return "";
  }

  return CLIENT_URLS.Profile.replace("{username}", authUser.value.username);
});

const mainNav = computed(() => {
  return [
    {
      name: "Home",
      uri: "/",
      active: route.path === "/",
    },
    {
      name: "My Feed",
      uri: CLIENT_URLS.Feed,
      active: route.path === CLIENT_URLS.Feed,
    },
    {
      name: "Creators Guide",
      uri: CLIENT_URLS.CreatorsGuide,
      active: route.path === CLIENT_URLS.CreatorsGuide,
    },
    {
      name: "Learners Guide",
      uri: CLIENT_URLS.LearnersGuide,
      active: route.path === CLIENT_URLS.LearnersGuide,
    },
  ];
});
</script>
